import {Controller} from "@hotwired/stimulus"
import {useClickOutside} from 'stimulus-use'

// Connects to data-controller="navbar"
export default class extends Controller {
    static targets = ["toggleable"]

    connect() {
        useClickOutside(this)
    }

    clickOutside(event) {
        // example to close a modal
        //event.preventDefault()
        this.toggleableTarget.classList.add('opacity-0')
        this.toggleableTarget.classList.add('invisible')
        this.toggleableTarget.classList.remove('opacity-100')
        this.toggleableTarget.classList.remove('visible')
    }

    toggle() {
        this.toggleableTarget.classList.toggle('opacity-100')
        this.toggleableTarget.classList.toggle('opacity-0')
        this.toggleableTarget.classList.toggle('visible')
        this.toggleableTarget.classList.toggle('invisible')
    }
}
